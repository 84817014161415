import React, {useEffect, useRef, useState} from 'react';
import './index.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from "@fortawesome/free-solid-svg-icons";

function Contact() {
  const formRef = useRef(null)
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [contacting, setContacting] = useState(false);
  const handleNameChange = (e) => {
    setName(e.target.value)
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value)
  };
  const handleSubjectChange = (e) => {
    setSubject(e.target.value)
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value)
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setContacting(true);
    const response = await fetch('https://sendmemail.herokuapp.com/email/send', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(
        {
          "name": name,
          "email": email,
          "phone": phone,
          "subject": subject,
          "message": message
        }
      )
    });
    setContacting(false);

    setSuccess(true);
    setMessage('')
    setEmail('')
    setPhone('')
    setName('')
    setSubject('')
    window.ga('send', 'event', 'Lead', 'submit');
  };

  return (
    <section id="contact">

      <div className="row narrow section-intro with-bottom-sep animate-this">
        <div className="col-twelve">
          <h3>Contact</h3>
          <h1>Get In Touch.</h1>

          <p className="lead">Do you have an existing product or packaging sample you’d like to share with us? Get in touch with us offline or send us a pick-up request and our experts will reach out to you with a solution.</p>
        </div>
      </div>

      <div className="row contact-content">

        <div className="col-seven tab-full animate-this">

          <h5>Send Us A Message</h5>

          <form ref={formRef} name="contactForm" id="contactForm" onSubmit={(e) => {
            handleFormSubmit(e)
          }}>

            <div className="form-field">
              <input onChange={(e) => {
                handleNameChange(e)
              }} name="contactName" type="text" id="contactName" placeholder="Name" value={name} minLength="2"
                     required={true}/>
            </div>

            <div className="row">
              <div className="col-six tab-full">
                <div className="form-field">
                  <input onChange={(e) => {
                    handleEmailChange(e)
                  }} name="contactEmail" type="email" id="contactEmail" placeholder="Email" value={email}
                         required={true}/>
                </div>
              </div>
              <div className="col-six tab-full">
                <div className="form-field">
                  <input onChange={(e) => {
                    handlePhoneChange(e)
                  }} name="contactPhone" type="text" id="contactPhone" placeholder="Phone Number" value={phone}/>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-twelve tab-full">
                <div className="form-field">
                  <input onChange={(e) => {
                    handleSubjectChange(e)
                  }} name="contactSubject" type="text" id="contactSubject" placeholder="Subject" value={subject}/>
                </div>
              </div>
            </div>

            <div className="form-field">
              <textarea onChange={(e) => {
                handleMessageChange(e)
              }} value={message} name="contactMessage" id="contactMessage" placeholder="Write your message here..." rows="10" cols="50"
                        required={true}/>
            </div>

            <div className="form-field">
              <button disabled={contacting} className="submitform">{contacting ? 'Contacting...' : 'Submit'}</button>
            </div>

          </form>
          {success && <div id="message-success">
            <FontAwesomeIcon icon={faCheck}/> Your message was sent, thank you!<br/>
          </div>}

        </div>
        <div className="col-four tab-full contact-info end animate-this">

          <h5>Contact Information</h5>

          <div className="cinfo">
            <h6>Where to Find Us</h6>
            <p>Godown No.1, Harpale Park, <br/>Laxmi Niwas. Phursungi. <br/>Pune, Maharashtra 412308, IN.</p>
          </div>
          <div className="cinfo">
            <h6>Email Us At</h6>
            <p>
              sales@leafpack.in
            </p>
          </div>
          <div className="cinfo">
            <h6>Call Us At</h6>
            <p>
              Phone: +91 96257 03915<br/>
              Mobile: +91 93042 04822<br/>
            </p>
          </div>

        </div>
      </div>
    </section>
  );
}

export default Contact;