import React, {useEffect} from 'react';
import './index.scss';
import {list as ProductList} from './../Products/products'

function Products() {
  useEffect(() => {
    document.getElementsByClassName('page-loader')[0].style.display = 'none';
    return () => {
      document.getElementsByClassName('page-loader')[0].style.display = 'block';
    }
  }, [])

  useEffect(() => {
    document.getElementById('header').classList.add('products');
  }, []);

  const onProductClick = (product) => {
    document.getElementsByClassName('page-loader')[0].style.display = 'block';
    window.location.href = "/product/" + product.id
  };

  return (
    <section id={'products'}>
      <div className="row narrow section-intro with-bottom-sep">
        <div className="col-full">

          <h3>Products</h3>
        </div>
      </div>
      <div className={'row'}>
        <div className={'product-list'}>

          {ProductList.map((product, index) => {
            return <div onClick={() => {
              onProductClick(product)
            }} key={index} className="product">
              <div className={'profile-img'} style={{backgroundImage: `url(${product.banner})`}}/>
              <h3>
                {product.name}
              </h3>
            </div>
          })}
        </div>

      </div>
    </section>
  );
}

export default Products;