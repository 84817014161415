import React from 'react';


function Loader() {
  return (
    <div className={'page-loader'}>
      <img src={'images/loader.gif'}/>
    </div>
  );
}

export default Loader;