import React, {useEffect, useState} from 'react';
import './index.scss';
import {list} from "../products";

function Product(props) {
  const [product, setProduct] = useState(null);
  useEffect(() => {
    document.getElementsByClassName('page-loader')[0].style.display = 'none';
    return () => {
      document.getElementsByClassName('page-loader')[0].style.display = 'block';
    }
  }, []);
  useEffect(() => {
    document.getElementById('header').classList.add('product');
  }, []);

  useEffect(() => {
    const productId = props.match.params.id;
    const product = list.find((product) => product.id === productId);
    setProduct(product);
    document.title = `LEAF- ${product.name}`
    document.querySelector('meta[name="description"]').setAttribute("content", product.description);
    document.querySelector('meta[property="og:description"]').setAttribute("content", product.description);
    return () => {
      document.title = `LEAF`
      document.querySelector('meta[name="description"]').setAttribute("content", "Your one-stop packaging destination.");
      document.querySelector('meta[property="og:description"]').setAttribute("content", "Your one-stop packaging destination.");
    }
  }, []);

  if (!product) {
    return null;
  }
  return (
    <section id={'product'} className={'product'}>
      <div className="row narrow section-intro with-bottom-sep">
        <div className="col-full">
          <h3>{product.type}</h3>
          <h1>{product.name}</h1>
        </div>
      </div>
      <div className={'row'}>

        <div className={'banner'} style={{textAlign: "center"}}>
          <img className={'banner-img'} src={'../' + product.profile}/>
        </div>

        <div className={'section'}>
          <h3>Description</h3>
          <div className={'description'}>
            {product.description}
          </div>
        </div>

        {product.variants && <div className={'section'}>
          <h3>Variants</h3>
          <div className="social">
            {product.variants.map((variant) => {
              return <a key={variant}>{variant}</a>
            })}

          </div>
        </div>}

        <div className={'section'}>
          <h3>Material</h3>
          <div className="social">
            {product.tags.map((tag) => {
              return <a key={tag}>{tag}</a>
            })}

          </div>
        </div>

        <div className={'section'}>
          <h3>{product.footer.title}</h3>
          <div className="social">
            {product.footer.items.map((tag) => {
              return <a key={tag}>{tag}</a>
            })}

          </div>
        </div>


      </div>
    </section>
  );
}

export default Product;