import React, {useEffect, useState} from 'react';
import './index.scss';
import {faFacebook, faLinkedin, faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Counters from './counters.json';
import Counter from './Counter'

function Home() {

  const [activeCounter, setActiveCounter]= useState(Counters.counts[0])

  const scrollToNext = (e) => {
    e.preventDefault();

    let yOffset;
    const element = document.getElementById('services');
    if(window.innerWidth>768){
      yOffset= 110
    }else{
      yOffset= 80

    }
    window.scrollTo({top: window.innerHeight+yOffset, behavior: 'smooth'});

  };

  const updateCounter=()=>{
    let currentIndex = 0;
    setInterval(() => {
      currentIndex++;
      setActiveCounter(Counters["counts"][currentIndex % 3])
    }, 4000)
  }
  useEffect(() => {
    if(window.innerWidth< 768){
      updateCounter()
    }

  }, [])

  return (
    <section id="home">

      <div className={'home-container'}>
        <h3>We Are LEAF</h3>
        <h1 className="animate-intro-small">
          We provide <br/>packaging solutions <br/>
        </h1>
      </div>


      <div className="counter animate-intro"
           style={{position: 'absolute', bottom: 54, width: '100%', display: 'flex', justifyContent: 'center'}}>
        {Counters.counts.map(count => {
          if(window.innerWidth< 768 && count.id !== activeCounter.id){
            return null
          }
          return <Counter key={count.id} data={count}/>
        })
        }
      </div>

      <ul className="home-social-list">
        <li className="animate-intro">
          <a href="https://www.facebook.com/leafpack.in" target={"_blank"}>
            <FontAwesomeIcon icon={faFacebook}/>
          </a>
        </li>
        <li className="animate-intro">
          <a href="https://www.instagram.com/leafpack.in/" target={"_blank"}><FontAwesomeIcon icon={faInstagram}/></a>
        </li>
        <li className="animate-intro">
          <a href="https://www.linkedin.com/company/leafpackaging" target={"_blank"}><FontAwesomeIcon icon={faLinkedin} target={"_blank"}/></a>
        </li>
      </ul>
      <div className="scrolldown">
        <a onClick={scrollToNext} href="#services" className="scroll-icon smoothscroll">
          Scroll Down
          <FontAwesomeIcon icon={faArrowRight}/>
        </a>
      </div>
    </section>
  );
}

export default Home;