import React, {useEffect, useRef, useState} from 'react';
import './index.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPiggyBank, faRocket, faCertificate, faHandsHelping} from '@fortawesome/free-solid-svg-icons'

function Highlights() {
  const servicesRef1 = useRef(null);
  const servicesRef2 = useRef(null);
  const servicesRef3 = useRef(null);
  const servicesRef4 = useRef(null);

  useEffect(() => {
    const observeElements = [servicesRef1.current, servicesRef2.current, servicesRef3.current, servicesRef4.current];
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((element, index) => {
          if (entries[index].intersectionRatio > 0) {
            entries[index].target.classList.add('animate-this')
          }
        })

      }, {threshold: 0.05}
    );
    observeElements.forEach((el) => {
      observer.observe(el);
    });

    return () => {
      observeElements.forEach((el) => {
        observer.unobserve(el);
      });
    }

  }, []);

  let classnames = ['bgrid', 'service-item'];
  classnames = classnames.join(" ");


  return (
    <section id="services">

      <div className="row narrow section-intro with-bottom-sep">
        <div className="col-full">

          <h3>Services</h3>
          <h1>What We Do.</h1>

          <p className="lead">Today customer's feedback is the single most pillar on which you can build a long-lasting
            brand. The perception about your product depends on how you brand the packaging, which is one of the most
            important hands-on marketing you can do. At LEAF, we design, manufacture, and supply world-class packaging
            for the HORECA, Retail & E-commerce Industry. LEAF, at the same time, is synonymous with green and we make
            sure our brand is environmentally conscious and uses sustainable forms of packaging.</p>

        </div>
      </div>

      <div className="row services-content">

        <div className="services-list block-1-2 block-tab-full group">


          <div id={'inno-prdcts'} ref={servicesRef2} className={classnames}>

            <span className="icon">
              <FontAwesomeIcon icon={faRocket} size={'4x'}/>
            </span>

            <div className="service-content">
              <h3 className="h05">Innovation</h3>

              <p>We are constantly innovating with our packaging designs. At LEAF, we believe in providing solutions and
                not just products. Our design team can curate world-class designs of packaging for your brand and help
                you stand out from the crowd.
              </p>
            </div>

          </div>

          <div ref={servicesRef3} className={classnames}>

            <span className="icon">
              <FontAwesomeIcon icon={faPiggyBank} size={'4x'}/>
            </span>

            <div className="service-content">
              <h3 className="h05">Pocket Friendly</h3>

              <p> Without a doubt, pricing is something which in today’s market is a big deciding factor in choosing
                your packaging, but when you get the LEAF’s quality at LEAF’s prices, we are sure, you aren’t going
                anywhere else.
              </p>
            </div>


          </div>

          <div id={'env-frnd'} ref={servicesRef1} className={classnames}>
            <span className="icon">
              <FontAwesomeIcon icon={faCertificate} size={'4x'}/>
            </span>

            <div className="service-content">
              <h3 className="h05">Quality</h3>

              <p>Quality is something we aim for every single day at LEAF. We are supplying our products globally and in
                the local markets with identical qualities and that is something we are very proud of. We are trusted by
                umpteen recognized brands and strive to make them visible in the daily clutter of food packaging.
              </p>
            </div>


          </div>

          <div ref={servicesRef4} className={classnames}>

            <span className="icon">
              <FontAwesomeIcon icon={faHandsHelping} size={'4x'}/>
            </span>

            <div className="service-content">
              <h3 className="h05">Ready To Go Range</h3>

              <p>With over 50 products in our ready-to-go range, Bags, Cups and accessories, Plates, Bowls, Cutlery,
                Cartons and Trays, Boxes and Wraps are available in plain or subtle designs, you can easily add
                finishing touches like ties, labels, and stamping to make them part of your brand.

              </p>
            </div>

          </div>

        </div>

      </div>

    </section>
  );
}

export default Highlights;
