import React, {useEffect, useRef} from 'react';
import './index.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook, faLinkedin, faInstagram} from '@fortawesome/free-brands-svg-icons'

function Header() {
  const headerRef = useRef(null);
  const clickHandler = (e) => {
    if (!headerRef.current.contains(e.target) && document.body.classList.contains('menu-is-open')) {
      closeMenu()
    }
  };

  const handleMenuOpen = () => {
    document.body.classList.add('menu-is-open');
    document.body.classList.add('no-scroll');
    document.body.addEventListener('click', clickHandler, false)
  };

  const closeMenu = (showLoader) => {
    if (showLoader) {
      document.getElementsByClassName('page-loader')[0].style.display = 'block';
    }
    document.body.classList.remove('menu-is-open');
    document.body.classList.remove('no-scroll');
    document.body.removeEventListener('click', clickHandler, false)
  };

  useEffect(() => {
    const changeColorOnScroll = (y) => {
      if (window.location.href.indexOf("products") > -1 || window.location.href.indexOf("product/") > -1) {
        return;
      }
      if (y > window.innerHeight + document.getElementById('products').offsetHeight + document.getElementById('services').offsetHeight - headerRef.current.offsetHeight) {
        headerRef.current.classList.add('dark')
      } else {
        headerRef.current.classList.remove('dark')
      }
    };
    document.addEventListener("scroll", () => {
      changeColorOnScroll(window.scrollY)
    })
  }, []);

  return (
    <header ref={headerRef} id={'header'}>

      <div className="header-logo">
        <a href="/">Leaf</a>
      </div>

      <span onClick={handleMenuOpen} id="header-menu-trigger">
        <span className="header-menu-text">Menu</span>
        <span className="header-menu-icon"></span>
      </span>

      <nav id="menu-nav-wrap">

        <a onClick={() => {
          closeMenu()
        }} href="#" className="close-button" title="close"><span></span></a>

        <h3>LEAF</h3>

        <ul className="nav-list">
          <li className="current"><a onClick={() => {
            closeMenu()
          }} className="smoothscroll" href="/" title="Home">Home</a></li>
          <li><a onClick={() => {
            closeMenu()
          }} className="smoothscroll" href="/#products" title="Products">Products</a></li>
          <li><a onClick={() => {
            closeMenu()
          }} className="smoothscroll" href="/#services" title="Services">Services</a></li>
          <li><a download onClick={() => {
            closeMenu()
          }} className="smoothscroll" href="/documents/Leafpack-Catalogue.pdf" title="Catalogue">Catalogue</a></li>
          <li><a onClick={() => {
            closeMenu()
          }} className="smoothscroll" href="/#contact" title="">Contact</a></li>
        </ul>

        <ul className="header-social-list">
          <li>
            <a href="https://www.facebook.com/leafpack.in" target={"_blank"}>
              <FontAwesomeIcon icon={faFacebook}/>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/leafpack.in/" target={"_blank"}><FontAwesomeIcon icon={faInstagram}/></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/leafpackaging" target={"_blank"}><FontAwesomeIcon
              icon={faLinkedin}/>
            </a>
          </li>
        </ul>

      </nav>
    </header>
  );
}

export default Header;