import React, {useEffect} from 'react';
import './index.scss';
import Home from "../Home";
import Highlights from "../Highlights";
import Contact from "../Contact";
import Products from "../Products";

function Homepage() {

  useEffect(()=>{
    document.getElementsByClassName('page-loader')[0].style.display='none';
    return ()=>{
      document.getElementsByClassName('page-loader')[0].style.display='block';
    }
  },[])
  return (
    <React.Fragment>
      <Home/>
      <Products/>
      <Highlights/>
      <Contact/>
    </React.Fragment>
  );
}

export default Homepage;