import React, {useState} from 'react';
import './index.scss';
import Header from "../Header";
import {createBrowserHistory} from 'history';
import Homepage from "../Homepage";
import Products from "../Products";
import {Router, Route} from 'react-router-dom';
import Product from "../Products/product";
import Loader from "./Loader";
const history = createBrowserHistory();


function App() {
  const [loader, setLoader] = useState(false);
  return (
    <div id={'app'} className="App">
      <Header/>
      {loader && <Loader/>}
      <Router history={history}>
        <Route exact path={['/']}>
          <Homepage/>
        </Route>
        <Route path={['/products']}>
          <Products/>
        </Route>
        <Route path={['/product/:id']} component={Product}/>
      </Router>
    </div>
  );
}

export default App;
